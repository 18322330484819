import * as React from "react"
import { Link } from "gatsby"

import Seo from "../components/seo"

import InfoPopup from "../components/infopopup"
import MainMap from "../components/mainmap"
import { withTrans } from "../i18n/withTrans"

const IndexPage = ({t, ...props}) => (
<div style={{background: "#f7f626", overflowY: "hidden"}}>
  <Seo title="Home" />
  <main id="maincontent">
  <InfoPopup
  intro = {t("startseite.intro")}
  content={t("startseite.content")}
  confirmation = {t("startseite.go")}
  storageKey="mainmenuPopup"/>

  <MainMap/>

  <div className="bot4020" style={{position: "fixed", width:"100vw", }}>
    <p className="p11G" style={{textAlign: "center", margin:"0 auto"}}>
    <Link to="/impressum" style={{textDecoration:"none", color:"black"}}>
        {t("impressum")}
    </Link>
    <span> | </span>
    <Link to="/datenschutz" style={{textDecoration:"none", color:"black"}}>
        {t("datenschutz")}
    </Link>
    </p>
  </div>

</main>
</div>
)

export default withTrans(IndexPage)
