import * as React from "react"
import Image from "./image/zeichnungen"
import { Link } from "gatsby"
import i18next from "../i18n/config"


const VIEWPORT = {x: 1000, y:400};
const VIEW_PARRALAX_Y = 1000; //bigger number = less parallax
const SPACING_X = 1500;
const SPACING_Y = 800;
const OFFSET_X = -700;

const STATE={
    pos: [
        {x: -850/SPACING_X,   y:-300/SPACING_Y,  z:1}, //tisch mit tafel
        {x: -300/SPACING_X,   y:300/SPACING_Y,     z:4}, //runder staender
        {x: 1150/SPACING_X,    y:1/SPACING_Y,     z:2}, //bühne
        {x: 150/SPACING_X,    y:-100/SPACING_Y,  z:2}, //faltwand
        {x: 550/SPACING_X,   y:550/SPACING_Y,   z:2}, //viereck pentagon
        {x: -850/SPACING_X,   y:650/SPACING_Y,   z:4}, //lego
        {x: 150/SPACING_X,    y:900/SPACING_Y,   z:3}, //wand
        {x: 750/SPACING_X,   y:-400/SPACING_Y,  z:1}, //hängedings
        {x: 1650/SPACING_X,   y:400/SPACING_Y,   z:3}, //wand rechts
        {x: -850/SPACING_X,   y:150/SPACING_Y,  z:3},   //halbbogen
     ],
     sizeFactor:[
        //calculated with relativeSizes.py in src/images/zeichnungen by default, adjusted by hand
        1.4,
        0.58,
        1.166733306677329,
        1.4,
        1.3997120921305182,
        1.3498080614203456,
        1.3,
        1.4,
        0.9997001499250375,
        1.4285224545766197
     ],
     links: [
         {name_de: "Eröffnungsausstellung", name_en:"Opening Exhibition", link:"ausstellungen/erffnungssausstellung"},
         {name_de: "Informations system", name_en:"Information System", link:"ausstellungen/manandthecommunitydeutscherpavillon"},
         {name_de: "Gutes Spielzeug", name_en:"Good Toys", link:"ausstellungen/gutesspielzeug"},
         {name_de: "Wanderausstellung HfG", name_en:"HfG Ulm Travelling Exhibition", link:"ausstellungen/wanderausstellungderhfgstuttgart"},
         {name_de: "Ulmer Pavillon", name_en:"Ulm Pavilion ", link:"ausstellungen/ulmerpavillon"},
         {name_de: "Messestand d55", name_en:"d55 Fair Stand", link:"ausstellungen/d55"},
         {name_de: "Exposição da Escola Superior de Desenho, Ulm", name_en:"Exposição da Escola Superior de Desenho, Ulm", link:"ausstellungen/escoladedesenhodeulm"},
         {name_de: "Mensa-Ausstellung", name_en:"Canteen Exhibition", link:"ausstellungen/mensaausstellung"},
         {name_de: "The New Designers", name_en:"The New Designers", link:"ausstellungen/thenewdesigners"},
         {name_de: "Mostra Scuole di Industrial Design", name_en:"Mostra Scuole di Industrial Design", link:"ausstellungen/ausstellungsbeitragindersektionmostrascuolediidaufderxiitriennaledimilano"},
     ],
     filterQueries: [
       "",
       "",
       "",
       "Wanderausstellung",
       "",
       "",
       "",
       "",
       "",
       "",
     ]
 }


export default class MainMap extends React.Component {
	constructor(props){
		super(props)
        this.state = {
            dx:0, dy:0,
            dxSmooth:0, dySmooth:0,
            mousedown: false,
            arrows: {up:false, down:false, left:false, right:false},
            windowMin:1000,
            w: 0, h:0,
            lastPos:{x:0,y:0},
            mounted:true,
        };

        this.mapLoop = this.mapLoop.bind(this);
        this.drag = this.drag.bind(this);
        this.cap = this.cap.bind(this);
        this.createWrapper = this.createWrapper.bind(this);
        this.keyHandler = this.keyHandler.bind(this);
        this.resizeHandler = this.resizeHandler.bind(this);
	}

    cap(val, is_x){
        var mx  = VIEWPORT.x/SPACING_X * this.state.windowMin;
        var my  = VIEWPORT.y/SPACING_Y * this.state.windowMin;

        if(is_x){
            return Math.max(Math.min(val,mx),-mx) ;
        }
        else{
            return Math.max(Math.min(val,my),-my);
        }

    }

    resizeHandler(){
      if (this.state.mounted){
        let w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        let h = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;
        this.setState({windowMin : Math.min(w,h), w: w, h: h})
      }
    }

  	componentDidMount(){
          //map controls
          document.addEventListener("keydown", this.keyHandler, false);
          document.addEventListener("keyup", this.keyHandler, false);
          //drag
          document.addEventListener("pointermove", this.drag, false);
          //handle resizes to adjust drag speed
          this.resizeHandler();
          document.addEventListener("onresize", this.resizeHandler, false);
          if(window.attachEvent) {
              window.attachEvent('onresize', this.resizeHandler);
          }
          else if(window.addEventListener) {
              window.addEventListener('resize', this.resizeHandler, true);
          }
          //start animation loop
          requestAnimationFrame(this.mapLoop);
  	}

    componentWillUnmount(){
      this.setState({mounted: false});
    }

    mapLoop(){
      if (this.state.mounted) {
        //handle arrow keys
        this.setState({
            dx: this.cap(this.state.dx + (15 * this.state.arrows.left) + (-15 * this.state.arrows.right), true),
            dy :this.cap(this.state.dy + (15 * this.state.arrows.up) + (-15 * this.state.arrows.down),  false)
        })
        //apply exponential smoothing to dx and dy
        if(Math.abs(this.state.dxSmooth - this.state.dx)>0.1 || Math.abs(this.state.dySmooth - this.state.dy)>0.1){
            this.setState({
                dxSmooth: 0.95*this.state.dxSmooth + 0.05*this.state.dx,
                dySmooth: 0.95*this.state.dySmooth + 0.05*this.state.dy,
            })
        }


        requestAnimationFrame(this.mapLoop);
      }
    }


    drag(e){
      if (this.state.mounted){
        e.preventDefault();
        //change dx and dy based on user drag, adapt to make it platform agnostic
        if (this.state.mousedown){
            this.setState({
                dx: this.cap(this.state.dx + (e.pageX - this.state.lastPos.x), true),
                dy: this.cap(this.state.dy + (e.pageY - this.state.lastPos.y), false),
                lastPos : {x: e.pageX, y: e.pageY},
            });
        }
      }
    }

    keyHandler(e){
        //keep track of what arrow buttons are currently pressed
        if(e.type==="keydown"){
            this.setState({
                arrows:{
                    up:     e.keyCode===38,
                    down:   e.keyCode===40,
                    left:   e.keyCode===37,
                    right:  e.keyCode===39,
                }
            })
        }
        else if (e.type==="keyup"){
            this.setState({
                arrows:{
                    up:     !e.keyCode===38,
                    down:   !e.keyCode===40,
                    left:   !e.keyCode===37,
                    right:  !e.keyCode===39,
                }
            })
        }
    }

    createWrapper(ids){
        let res = []
        for (var id in ids) {
            res.push(
                <div style={{
                    position: "absolute",
                    pointerEvents: "none",
                    transform: `translate3d(0px,`+
                        (this.state.dySmooth*(1 + (STATE.pos[id].y*this.state.h)/VIEW_PARRALAX_Y))+`px,0)`,
                    left: ((STATE.pos[id].x + OFFSET_X/SPACING_X)*this.state.windowMin + this.state.w/2)||0,
                    top: (STATE.pos[id].y*this.state.windowMin)||0,
                    zIndex: STATE.pos[id].z,
                    userSelect:"none",
                    width: "min("+STATE.sizeFactor[id]*50+"vw,"+STATE.sizeFactor[id]*50+"vh)",
                }}
                key = {id}
                className="mainmapdiv unselectable touchNone"
                onClick={(e)=>{
                    try {
                        e.target.parentNode.children[2].style.opacity = e.target.parentNode.children[2].style.opacity==="0"?"1":"0"
                        e.target.parentNode.children[1].style.opacity = e.target.parentNode.children[1].style.opacity==="0"?"1":"0"
                        e.target.parentNode.children[1].style.pointerEvents = e.target.parentNode.children[1].style.pointerEvents==="none"?"all":"none"
                    } catch (e) {/*do nothing - error is caught when link is clicked*/}
                }}
                >
                    <Image
                        src={"zeichnungen/"+((2*id)+1)+".png"}
                        style={{
                            width: "100%",
                            pointerEvents: "none",
                        }}
                        alt={STATE.links[id].name_de}
                    />
                    <p style={{
                        fontFamily:"Gotham", pointerEvents:"none",
                        width:"100%", textAlign:"center", zIndex:"5",  top:"50%", position:"absolute", transform: "translateY(-50%)",
                        fontSize:"min(3vw,3vh)", lineHeight:"calc(min(3vw,3vh)*2)",
                        opacity:"0"
                    }}>
                        <Link to={`${STATE.links[id].link}`}  state={{ filterQuery: STATE.filterQueries[id] }} style={{textDecoration:"none", color:"white", background:"black", padding:"10px"}}>
                            {i18next.language==="de"? STATE.links[id].name_de.toUpperCase() : STATE.links[id].name_en.toUpperCase()}
                        </Link>
                    </p>


                    <div className = "hideOnHover" style={{
                        position:"absolute",
                        left:"0",
                        top:"0",
                        transition:"opacity ease 300ms",
                        pointerEvents:"auto",
                        width: "100%",
                    }}>
                        <Image
                            src={"zeichnungen/"+2*id+".png"}
                            style={{
                                width: "100%",
                                pointerEvents: "none",
                            }}
                            alt={STATE.links[id].name_de}
                        />
                    </div>
                </div>
            )
        }
        return res
    }



	render() {
        return (
            <div
                style={{width: "100vw", height: "150%", position: "fixed", top:"0",  background:"#f7f626", overflow: "hidden", userSelect:"none", touchAction:"none"}}
                className = "touchNone"
                onPointerDown={((e)=>{this.setState({mousedown: true, lastPos:{x: e.pageX, y: e.pageY}})})}
                onPointerUp={((e)=>{this.setState({mousedown: false})})}
                onPointerLeave={((e)=>{this.setState({mousedown: false})})}
            >
                <div
                    style={{
                        position:"fixed", top:"0", left:"0",
                        transform: `translate3d(`+(this.state.dxSmooth)+`px,0,0)`,}}>
                    {this.createWrapper([...Array(10).keys()])}
                </div>
            </div>
        )
	}
}
