import React, { setState } from "react"
import closebutton from '../assets/closebutton.svg';
import dragMp4 from '../video/animation_drag_mouse.mp4';
import dragWebm from '../video/animation_drag_mouse.webm';

export default class InfoPopup extends React.Component {
	constructor(props){
		super(props)
        this.dialogue = React.createRef();
        this.hideMe = this.hideMe.bind(this);
        this.showMe = this.showMe.bind(this);
        this.state = {
          hidden: false
        }
	}
	componentDidMount(){
        this.setState({hidden: localStorage.getItem(this.props.storageKey) || false}, () => {
            if(this.state.hidden){
                this.hideMe()
            } else{
                setTimeout(this.showMe, 100);
            }
        })
	}

	componentWillUnmount(){
		document.getElementsByClassName("langSwitchImg")[0].classList.remove("animate");
		document.getElementsByClassName("langSwitch")[0].classList.remove("animate");
	}

    showMe(){
        this.dialogue.current.style.transform = "translate(-50%,-50%)";
        this.dialogue.current.style.opacity = 1;

				document.getElementsByClassName("langSwitchImg")[0].classList.add("animate");
				document.getElementsByClassName("langSwitch")[0].classList.add("animate");

    }

    hideMe(){
        this.dialogue.current.style.opacity = 0;
        this.dialogue.current.style.pointerEvents = "none";
        this.setState({hidden:true});
        localStorage.setItem(this.props.storageKey, true);

				document.getElementsByClassName("langSwitchImg")[0].classList.remove("animate");
				document.getElementsByClassName("langSwitch")[0].classList.remove("animate");
    }

	render() {
        return (
          <div ref={this.dialogue} style={{
            background: `white`,
            fontFamily: "Gotham",
            padding: "25px",
            width:"60vw",
            zIndex:"2",
            position:"fixed",
            top:"50vh",
            left:"50vw",
            transform: "translate(-50%, 0%)",
            transition: "opacity 300ms ease, transform 500ms ease",
            opacity: 0,
          }}>
            <div style={{
              display: "flex",
            }}>
							<div>
	              <p>{this.props.intro}</p>
								<p>{this.props.content}<br/></p>
								<video autoplay="autoplay" muted="true" loop="true" style={{width:"150px", margin:"auto", display:"block"}}>
									<source src={dragWebm} type="video/webm"/>
									<source src={dragMp4} type="video/mp4"/>
								</video>
							</div>
              <input
                type="image"
                src={closebutton}
                alt="Close"
                onClick={this.hideMe}
                style={{
                    marginTop: "-20px",
                    marginRight: "-15px",
                    cursor:"pointer",
                    height: "25px"
                }}/>
            </div>
            <input
              type="button"
              alt="Close"
              onClick={this.hideMe}
              value = {this.props.confirmation}
              style = {{
                fontFamily: "BlueMono",
                padding: "0",
                border: "none",
                background: "none",
                cursor: "pointer",
								float:"right",
								marginTop:"10px",
              }}/>
          </div>
        )
	}
}
